import React, {useEffect, useState} from 'react';
import './App.css';
import axios from 'axios'

function App() {
  const [btcPrice, setBtcPrice] = useState<number>(0)
  const [ethPrice, setEthPrice] = useState<number>(0)
  const [lastUpdate, setLastUpdate] = useState<Date | undefined>(undefined)
  const btcHoldings = 0.01814385
  const ethHoldings = 0.3834
  const btcInitialInvestment = 1100
  const ethInitialInvestment = 1013
  const btcHoldingsInUsd = btcPrice * btcHoldings
  const ethHoldingsInUsd = ethPrice * ethHoldings
  const differenceBtcInUsd = btcHoldingsInUsd - btcInitialInvestment
  const differenceEthInUsd = ethHoldingsInUsd - ethInitialInvestment

  const updatePrices = () => {
    axios.get(
        'https://api.coinbase.com/v2/exchange-rates'
    ).then((response) => {
      const {ETH,BTC} = response.data.data.rates
      setBtcPrice(1 / BTC)
      setEthPrice(1 / ETH)
      setLastUpdate(new Date())
    });
  }

  useEffect(() => {
    updatePrices()
    setInterval(() => updatePrices(), 7000)
  }, [])
  return (
    <div className="App">
      <div>
        <h1>Dandelion Labs Joint investment</h1>
        {lastUpdate && <p style={{color: 'gray'}}>Last update: {lastUpdate.toDateString()} - {lastUpdate.toLocaleTimeString()}</p>}
        <p><strong>BTC Initial investment:</strong> {btcInitialInvestment} USDT</p>
        <p><strong>ETH Initial investment:</strong> {ethInitialInvestment} USDT</p>
        <p><strong>Current BTC price:</strong> {btcPrice.toFixed(2)} USD</p>
        <p><strong>Current ETH price:</strong> {ethPrice.toFixed(2)} USD</p>
        <p><strong>BTC Holdings:</strong> {btcHoldings} ({btcHoldingsInUsd} USD) <strong style={{color: differenceBtcInUsd >= 0 ? "green" : "red"}}>{((100 / btcInitialInvestment) * differenceBtcInUsd).toFixed(2)}%</strong></p>
        <p><strong>ETH Holdings:</strong> {ethHoldings} ({ethHoldingsInUsd} USD)  <strong style={{color: differenceEthInUsd >= 0 ? "green" : "red"}}>{((100 / ethInitialInvestment) * differenceEthInUsd).toFixed(2)}%</strong></p>
      </div>
    </div>
  );
}

export default App;
